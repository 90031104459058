import React from "react"
import Layout from "../components/Layout"
// import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Vorstand = props => {
  // const data = useStaticQuery(graphql`
  //   {
  //     file(relativePath: { eq: "VorstandWPF2020groß.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1920, quality: 100) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //   }
  // `)
  return (
    <Layout
      title="Vorstand"
      secondTitle=""
      alt="Personen Vorstand WirtschaftsPlattForm"
      // fluid={data.file.childImageSharp.fluid}
      seoTitle="WirtschaftsPlattForm Schwechat"
      seoDescription="Die WirtschaftsPlattForm Schwechat wurde mit dem Ziel gegründet, den Standort Schwechat als Einkaufsstadt für den Konsumenten attraktiver zu gestalten und dadurch die in Schwechat wirksame Kaufkraft zu erhöhen. Die WirtschaftsPlattForm Schwechat besteht als Verein und arbeitet nicht gewinnorientiert."
      heroSize="vorstand"
    >
      <section className="section">
        <div className="container">
          <aside className="menu">
            <p className="menu-label is-size-5">Vorstand</p>
            <ul className="menu-list">
              <li>
                <ul>
                  <p className="is-size-5">
                    Elisabeth Strini - Obfrau, 2.v.re., Inhaberin von
                    Buchhandlung am Hauptplatz
                  </p>
                </ul>
                {/* <li>
                  <ul>
                    <p className="is-size-5">
                       - Obfraustellvertreter - 2.v.li., Inhaber
                      vom Radhaus am Rathaus
                    </p>
                  </ul>
                </li> */}
                <li>
                  <ul>
                    <p className="is-size-5">
                      Monika Stolzer - Kassierin, 3.v.li., Inhaberin von MO Line
                      Boutique
                    </p>
                  </ul>
                </li>
                <li>
                  <ul>
                    <p className="is-size-5">
                      Elisabeth Zepnik - Schriftführerin, 1.v.li,. Inhaberin von
                      Hornicek Schuh'kompetenz
                    </p>
                  </ul>
                </li>
                <li>
                  <ul>
                    <p className="is-size-5">
                      Mag. Angela Simunek - Schriftführerin- Stellvertreterin,
                      1.v.re,. Inhaberin von Stadtapotheke Schwechat
                    </p>
                  </ul>
                </li>
              </li>
            </ul>
          </aside>
          <div className="has-text-centered pt-6">
            <Link to="/" className="button is-link is-medium">
              Zur Startseite
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Vorstand
